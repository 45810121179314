import React, { useState, useEffect } from "react";
import { NavLink, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PrivateView from "../HOC/PrivateView/PrivateView";
import { useLocation } from "react-router-dom";
import {
  Sidenav,
  Nav,
  Panel,
  Avatar,
  Dropdown,
  IconButton,
  Animation,
} from "rsuite";
import LegacyAlignJustifyIcon from "@rsuite/icons/legacy/AlignJustify";

// Redux
import { logout } from "../../actions/auth";
import { useNavigationData } from "../../hooks/useNavigationData";

const NavBar = ({ logout, user, navigationData, history, props }) => {
  const { Slide } = Animation;
  // get the user
  let name = "";
  const [toggle, setToggle] = useState(false);
  const location = useLocation();
  const { navData, isNavLoading } = useNavigationData();

  useEffect(() => {
    setToggle(false);
    document.body.classList.remove("no-scroll");
    //* when location change, that means user clicked to a new page, then the menu should be hidden by default
  }, [location]);

  if (user !== null) name = user.firstName[0] + " " + user.lastName[0];

  return (
    <>
      <Panel style={{ overflow: "visible" }}>
        <div className="mobile-nav">
          <a href="/" className="navbar-brand logo">
            <img width="140" src="/img/logo.png" />
          </a>
          <IconButton
            style={{ float: "right" }}
            onClick={() => {
              setToggle(!toggle);
              document.body.classList.toggle("no-scroll");
            }}
            size="lg"
            appearance="secondary"
            icon={<LegacyAlignJustifyIcon />}
          />
        </div>

        <Slide
          in={toggle}
          placement="right"
          className="mobile-sidenav"
          unmountOnExit={true}
        >
          <Sidenav appearance="subtle">
            <div className="mobile-nav" style={{ padding: "20px" }}>
              <a href="/" className="navbar-brand logo">
                <img width="140" src="/img/logo.png" />
              </a>
              <IconButton
                style={{ float: "right" }}
                onClick={() => {
                  setToggle(!toggle);
                  document.body.classList.toggle("no-scroll");
                }}
                size="lg"
                appearance="secondary"
                icon={<LegacyAlignJustifyIcon />}
              />
            </div>
            <Sidenav.Body style={{ background: "#FAFBFF" }}>
              {/* nav main content */}
              <Nav style={{ height: "100vh", overflowY: "scroll" }}>
                {!isNavLoading &&
                  Object.keys(navData).map((navGroup, index) =>
                    navData[navGroup].length <= 1 ? (
                      // non group nav item
                      navData[navGroup].length === 0 ? (
                        ""
                      ) : (
                        <Nav.Item
                          href={navData[navGroup][0].path}
                          key={index}
                          active={
                            navData[navGroup][0].path === location.pathname
                          }
                        >
                          {navData[navGroup][0].label}
                        </Nav.Item>
                      )
                    ) : (
                      <Nav.Menu trigger="hover" title={navGroup}>
                        {navData[navGroup].map((item) => (
                          <NavLink to={item.path}>
                            <Nav.Item
                              key={index}
                              active={
                                item.path.split("/")[1] ===
                                location.pathname.split("/")[1]
                              }
                              href={item.path}
                            >
                              {item.label}
                            </Nav.Item>
                          </NavLink>
                        ))}
                      </Nav.Menu>
                    ),
                  )}

                {/* the profile dropdown at the end */}
                <Nav.Menu
                  placement="bottomEnd"
                  title={
                    <div style={{ marginTop: "-5px" }}>
                      <span style={{ marginRight: "5px" }}>{name}</span>{" "}
                      <Avatar size="sm" circle>
                        {name[0]}
                      </Avatar>
                    </div>
                  }
                >
                  {user.permission === 1 ? (
                    <Nav.Item
                      style={{ width: "120px" }}
                      onSelect={() =>
                        history.push(`/profile/view/${user._id[0]}`)
                      }
                    >
                      Profile
                    </Nav.Item>
                  ) : (
                    ""
                  )}
                  <Nav.Item
                    style={{ width: "120px" }}
                    onSelect={async () => {
                      await logout();
                      history.push("/");
                    }}
                    eventKey="4"
                  >
                    Logout
                  </Nav.Item>
                </Nav.Menu>
              </Nav>
            </Sidenav.Body>
          </Sidenav>
        </Slide>
      </Panel>
    </>
  );
};

NavBar.propTypes = {
  logout: PropTypes.func.isRequired,
};

const mapStateToProp = (state) => ({
  user: state.auth.user,
  navigationData: state.settings.navigationData,
});

export default withRouter(connect(mapStateToProp, { logout })(NavBar));
