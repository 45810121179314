import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import defaultAxios from "../../../axios/Axios";

import { Redirect } from "react-router-dom";
import { useWindowSize } from "../../../utils/useWindowSize";

// Import the UI component
import { toaster, Pane, Heading, Button} from "evergreen-ui";

import {
  getStudentDocuments,
  setDocumentsLoading,
} from "../../../actions/documents";

import ListComponent from "./submittedDocumentsList";

const StudentDashboard = ({
  history,
  getStudentDocuments,
  list,
  totalCount,
}) => {
  const {width, height} = useWindowSize(); 
  const [filter, setFilter] = useState({
    department: "",
    type: "",
    page: 0,
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getStudentDocuments(filter);
  }, [filter.page, filter.department, filter.type]);

  const onfilterChange = (key, value) => {
    setFilter({ ...filter, [key]: value });
  };

  return (
    <Pane className="container">
      <Pane display={"flex"} flexDirection={width > 700 ? "row": "column"} marginBottom={16}>
          <Heading size={800}> All Documents</Heading>
          <Pane display="flex" >
            <Button
              onClick={() => history.push("/documents/pendingdocuments")}
            >
              Pending Documents
            </Button>
          </Pane>
      </Pane>

      <Pane>
        <ListComponent
          page={filter.page}
          lists={list}
          filter={filter}
          history={history}
          // loading={loading}
          totalCount={totalCount}
          onfilterChange={onfilterChange}
        />
      </Pane>
    </Pane>
  );
};

const mapStateToProps = (state) => ({
  list: state.documents && state.documents.docs,
  totalCount: state.documents && state.documents.totalDocs,
  // loading : state.courses.loading
});

export default connect(mapStateToProps, { getStudentDocuments })(
  StudentDashboard,
);
