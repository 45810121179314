import React from "react";
// Import the UI component
import ReactPaginate from "react-paginate";
import viewFile from "../../../utils/viewFile";
import { Button, Heading, Table, Card, Text , Spinner} from "evergreen-ui";

const SubmittedDocumentsList = ({
  props,
  lists,
  totalCount,
  onfilterChange,
  page,
}) => {
  return (
    <div>
      {lists ? (
          <Table>
            <Table.Head>
              <Table.TextHeaderCell marginLeft={10}>Type</Table.TextHeaderCell>

              <Table.TextHeaderCell marginLeft={10}>Dept</Table.TextHeaderCell>

              <Table.TextHeaderCell marginRight={-20}>
                Download
              </Table.TextHeaderCell>
            </Table.Head>
            <Table.Body>
              {lists.map((value) => {
                const id = value._id;
                return (
                  <Table.Row
                    display="flex"
                    alignItems="center"
                    height="auto"
                    minHeight={60}
                  >
                    <Table.Cell>
                      <Text marginLeft={8}>{value.type || value.fileType}</Text>
                    </Table.Cell>
                    <Table.Cell>
                      <Text>{value.department || "Orientation"}</Text>
                    </Table.Cell>

                    <Table.Cell>
                      <Button
                        appearance="primary"
                        onClick={() =>
                          viewFile(
                            value.fileInfo && value.fileInfo
                              ? value.fileInfo
                              : value,
                          )
                        }
                      >
                        Download
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
      ) : (
        <Spinner/>
      )}
    </div>
  );
};

export default SubmittedDocumentsList;
