// Set the alert Types
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

// Set the auth Types
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOAD_USER = "LOAD_USER";
export const AUTH_FAIL = "AUTH_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT = "LOGOUT";
export const REGENERATE_TOKEN = "REGENERATE_TOKEN";
export const TOKEN_EXTEND_OPEN = "TOKEN_EXTEND_OPEN";
export const TOKEN_EXTEND_CLOSE = "TOKEN_EXTEND_CLOSE";

// Set the orientation Types
export const ORIENTATION_SUCCESS = "ORIENRATION_SUCCESS";
export const ORIENTATION_FAILED = "ORIENTATION_FAILED";
export const SET_ORIENTATION = "SET_ORIENTATION";
export const GET_ORIENTATION = "GET_ORIENTATION";
export const GET_ORIENTATION_LIST = "ORIENRATION_SUCCESS";
export const ORIENTATION_LOADING = "ORIENTATION_LOADING";
export const ORIENTATION_COUNT = "ORIENTATION_COUNT";
export const ORIENTATION_REQUEST_LOADING = "ORIENTATION_REQUEST_LOADING";

// Set the retake types
export const SET_RETAKE = "SET_RETAKE";
export const RETAKE_ERROR = "RETAKE_ERROR";
export const GET_RETAKES = "GET_RETAKES";
export const RETAKE_LOADING = "RETAKE_LOADING";

// Set the schedule types
export const GET_SCHEDULES = "GET_SCHEDULES";
export const GET_GROUPS = "GET_GROUPS";
export const GET_ACADEMIC_PROFILE = "GET_ACADEMIC_PROFILE";
export const SET_GROUP = "SET_GROUP";
export const SET_GROUP_DENIED = "SET_GROUP_DENIED";
export const SET_SCHEDULE_LOADING = "SET_SCHEDULE_LOADING";

// Set the settings types
export const GET_SETTINGS = "GET_SETTINGS";

// Set the country ttoes
export const GET_COUNTRIES = "SET_COUNTIRIES";

// Get the class types
export const GET_CLASSES = "GET CLASSES";
export const GET_CLASSES_FILTERED_LIST = "GET_CLASSES_FILTERED_LIST";

// INTAKES
export const SET_INTAKES = "SET_INTAKES";
export const SET_PROGRAMS = "SET_PROGRAMS";
export const SET_DEPARTMENTS = "SET_DEPARTMENTS";

// Documents
export const GET_DOCUMENTS = "GET_DOCUMENTS";
export const SET_DOCUMENTS_LOADING = "SET_DOCUMENTS_LOADING";
export const PENDING_DOCUMENTS = "PENDING_DOCUMENTS";
export const GET_SELECTED_DOCUMENT = "GET_SELECTED_DOCUMENT";
export const GET_STAFF_SELECTED_DOCUMENT = "GET_STAFF_SELECTED_DOCUMENT";
export const SUBMIT_ATTACHED_DOCUMENT = "SUBMIT_ATTACHED_DOCUMENT";

export const GET_ALL_STUDENTS = "GET_ALL_STUDENTS";

// Get the course types
export const GET_COURSES = "GET COURSES";
export const SET_COURSE_LOADING = "SET_COURSE_LOADING";

// Get the programs types
export const GET_PROGRAMS = "GET PROGGET_PROGRAMS";

//Immigration documents
export const GET_IMMIGRATION_DOCUMENT_LIST = "GET_IMMIGRATION_DOCUMENT_LIST";
export const GET_SELECTED_IMMIGRATION_DOCUMENTS =
  "GET_SELECTED_IMMIGRATION_DOCUMENTS";
export const GET_DOCUMENT_STATUS_LIST = "GET_DOCUMENT_STATUS_LIST";

// Get the shuttle types
export const GET_PASSES = "GET_PASSES";
export const GET_STUDENT_PASSES = "GET_STUDENT_PASSES";

export const CURRENT_INTAKE = "CURRENT_INTAKE";
