import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOAD_USER,
  AUTH_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT,
  TOKEN_EXTEND_OPEN,
  TOKEN_EXTEND_CLOSE,
} from "../actions/types";

import quarantinePermissions from "../services/QuarantinePackage/Utils/Permissions";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  loading: true,
  user: null,
  intake: "",
  currentIntake: {},
  currentRegistrationIntake: "",
  tokenExtend: false,
  accessServices: {
    superOnly: { accessLevel: [0], departments: {} },
    allAdminsNdSuper: {
      accessLevel: [0, 2],
      departments: { $all: new Set(["admin", "Member"]) },
    },
    privatePublic: {
      accessLevel: [0, 1, 2],
      departments: { $all: new Set(["$all"]) },
    },
    approvedStudents: { accessLevel: [1], departments: {} },
    registrationStudents: { accessLevel: [3], departments: {} },
    privatePublicAll: {
      accessLevel: [0, 1, 2, 3],
      departments: { $all: new Set(["$all"]) },
    },
    exceptApprovedStudents: {
      accessLevel: [0, 2, 3],
      departments: { $all: new Set(["$all"]) },
    },

    orientation: {
      accessLevel: [0, 2],
      departments: { Orientation: new Set(["admin", "Member"]) },
    },
    orientationNdRegistrationStd: {
      accessLevel: [0, 2, 3],
      departments: { Orientation: new Set(["admin", "Member"]) },
    },
    orientationAdmins: {
      accessLevel: [0, 2],
      departments: { Orientation: new Set(["admin"]) },
    },

    retakes: {
      accessLevel: [0, 2],
      departments: { Retakes: new Set(["Member", "admin"]) },
    },
    retakesNdApprovedStd: {
      accessLevel: [0, 2, 1],
      departments: { Retakes: new Set(["admin", "Member"]) },
    },

    orientationandRetakes: {
      accessLevel: [0, 2, 3],
      departments: { Orientation: new Set(["admin", "Member"]) },
    },

    allApproved: { accessLevel: [1, 0, 2], departments: {} },
    GROUP_ADMIN: {
      accessLevel: [0, 2],
      departments: { Schedules: new Set(["admin", "Member"]) },
    },
    GROUP_ALL: {
      accessLevel: [0, 2, 1],
      departments: { Schedules: new Set(["$all", "Member"]) },
    },
    career: {
      // all user that is under career department
      accessLevel: [0, 2],
      departments: { Career: new Set(["admin", "Member"]) },
    },
    careerNdStudent: {
      accessLevel: [0, 1, 2],
      departments: { Career: new Set(["admin", "Member"]) },
    },
    profile: {
      accessLevel: [0, 2],
      departments: { Profile: new Set(["admin", "Member"]) },
    },
    eventsNdStudent: {
      accessLevel: [0, 1, 2],
      departments: { Events: new Set(["admin", "Member"]) },
    },
    ...quarantinePermissions,
  },
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_USER:
      return {
        ...state,
        user: payload.res,
        isAuthenticated: true,
        loading: false,
        intake: payload.intake,
        currentIntake: payload.currentIntake,
      };

    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      localStorage.setItem("token", payload.token);

      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
      };
    case REGISTER_FAIL:
    case AUTH_FAIL:
      localStorage.removeItem("token");

      return {
        ...state,
        ...payload,
        isAuthenticated: false,
        loading: false,
        // currentIntake : payload.currentIntake,
        // currentRegistrationIntake : payload.currentRegistrationIntake
      };

    case LOGOUT:
      localStorage.removeItem("token");

    case LOGIN_FAILED:
      localStorage.removeItem("token");
      return {
        accessServices: state.accessServices,
      };

    case TOKEN_EXTEND_CLOSE:
      return {
        ...state,
        ...payload,
        tokenExtend: false,
      };

    case TOKEN_EXTEND_OPEN:
      return {
        ...state,
        ...payload,
        tokenExtend: true,
      };

    default:
      return state;
  }
};
