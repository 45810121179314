import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import PageNotFound from "../components/pages/pageNotFound/PageNotFound";
import { isRoutePermitted } from "../utils/tools";
import Loading from "..//components/layout/Loading/Loading";

const PrivateRoute = ({
  component: Component,
  auth: { isAuthenticated, user, accessServices },
  accessService,
  ...rest
}) => {
  //? new addition  19/12/2020  - to remove the refresh error in the routing ***
  if (isAuthenticated === null)
    return <Route {...rest} render={(props) => <Loading {...props} />} />;
  // ------
  else if (isAuthenticated) {
    if (!!accessService) {
      let permissions;

      if (typeof accessService === "string") {
        permissions = { ...accessServices[accessService] } || {
          accessLevel: [],
        };
      } else {
        permissions = accessService;
      }

      let isPermitted = isRoutePermitted(
        user,
        permissions.accessLevel,
        permissions.departments,
      );

      if (isPermitted) {
        return <Route {...rest} render={(props) => <Component {...props} />} />;
      } else {
        return (
          <Route {...rest} render={(props) => <PageNotFound {...props} />} />
        );
      }
    } else {
      return <Route {...rest} render={(props) => <Component {...props} />} />;
    }
  } else {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )}
      />
    );
  }
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
