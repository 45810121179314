import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Timezone from "dayjs/plugin/timezone";
import "./App.css";
import "./Responsive.css";
import PrivateRoute from "./routing/PrivateRoute";
import ProtectedRoute from "./routing/ProtectedRoute";
// Redux
import { Provider } from "react-redux";
import store from "./store.js";
import { loadUser } from "./actions/auth";
import Alerts from "./components/layout/Alerts.js";
import PrivateView from "./components/HOC/PrivateView/PrivateView";
import NavBar from "./components/layout/NavBar";
import MobileNavBar from "./components/layout/MobileNavBar";
import Loading from "./components/layout/Loading/Loading";

import TokenExtend from "./components/layout/tokenExtendModal";

import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import queryClient from "./lib/queryClient";
import config from "./config";

// documents route;
import StudentDocumentList from "./components/pages/documents/studentListContainer";
import StudentDashboard from "./components/pages/documents/studentDashboard";
import SubmittedDocumentsList from "./components/pages/documents/submittedDocumentsList";
import PendingDocuments from "./components/pages/documents/pendingDocuments";

import DocumentLink from "./components/pages/idcard/PhotoDownload";

// new documents route
const DocumentRoute = React.lazy(() =>
  import("./components/pages/documents/View"),
);

// Import the components as pages
const Login = React.lazy(() => import("./components/auth/Login.js"));
const Register = React.lazy(() => import("./components/auth/Register.js"));

// const = React.lazy(() => import()) the orientation
const Orientation = React.lazy(() =>
  import("./components/pages/orientation/route.js"),
);
const IctPolicy = React.lazy(() =>
  import("./components/pages/orientation/ictPolicy.js"),
);

// Profile components
const ProfileRoutes = React.lazy(() =>
  import("./components/pages/profiles/route.js"),
);

// Immigration Form
const ImmigrationForm = React.lazy(() =>
  import("./components/pages/ImmigrationDocuments/ImmigrationForms.js"),
);
const ImmigrationList = React.lazy(() =>
  import("./components/pages/ImmigrationDocuments/ImmigrationList"),
);
const ImmigrationProfileView = React.lazy(() =>
  import("./components/pages/ImmigrationDocuments/ImmigrationProfileView"),
);

// Groups components
const GroupRoutes = React.lazy(() =>
  import("./components/pages/groups/route.js"),
);
const GroupSelectionStudentList = React.lazy(() =>
  import("./components/pages/groups/groupSelectionStudentList"),
);
const GroupSelectionDetails = React.lazy(() =>
  import("./components/pages/groups/groupSelectionDetails"),
);

// const = React.lazy(() => import()) the orientation
const Schedules = React.lazy(() =>
  import("./components/pages/schedules/Route.js"),
);

// Dashboard
const Dashboard = React.lazy(() =>
  import("./components/pages/dashboard/Dashboard.js"),
);
const EmptyPage = React.lazy(() =>
  import("./components/pages/dashboard/EmptyPage.js"),
);

// Retakes
const Retake = React.lazy(() => import("./components/pages/retakes/View"));
const ApplyRetake = React.lazy(() =>
  import("./components/pages/retakes/student/ApplyRetake"),
);
const ViewRetake = React.lazy(() =>
  import("./components/pages/retakes/staff/ViewRetake"),
);
const StudentRetake = React.lazy(() =>
  import("./components/pages/retakes/student/ViewRetake"),
);

const CreateStudents = React.lazy(() =>
  import("./components/pages/dashboard/CreateStudents"),
);
const UsersSettings = React.lazy(() =>
  import("./components/pages/users/UsersSettings/UsersSettings"),
);
const PostScheduleSettings = React.lazy(() =>
  import("./components/pages/schedules/PostScheduleSettings"),
);
const PreScheduleSettings = React.lazy(() =>
  import("./components/pages/schedules/PreScheduleSettings"),
);
const DepartmentSettings = React.lazy(() =>
  import("./components/pages/settings/Departments"),
);

// Courses lists
const Courses = React.lazy(() => import("./components/pages/courses/list.js"));
// Programs lists
const Programs = React.lazy(() =>
  import("./components/pages/programs/list.js"),
);
// Classes lists
const Classes = React.lazy(() => import("./components/pages/classes/list.js"));
// Intakes lists
const Intakes = React.lazy(() => import("./components/pages/intakes/list"));

//Student collectionlist
const StudentList = React.lazy(() =>
  import("./components/pages/students/list"),
);
const StudentView = React.lazy(() =>
  import("./components/pages/students/view"),
);
const StudentEditPage = React.lazy(() =>
  import("./components/pages/students/edit"),
);
const GroupEligibilityList = React.lazy(() =>
  import("./components/pages/profiles/GroupEligibilityList"),
);

// Error page
const PageNotFound = React.lazy(() =>
  import("./components/layout/PageNotFound.js"),
);

// Bus Psss Page
const BusPassView = React.lazy(() =>
  import("./components/pages/buspass/View.jsx"),
);
const Purchase = React.lazy(() =>
  import("./components/pages/buspass/Purchase.jsx"),
);

// Events
const Events = React.lazy(() => import("./components/pages/events/View.jsx"));
const SingleView = React.lazy(() =>
  import("./components/pages/events/SingleEvent.jsx"),
);
const StaffSingleView = React.lazy(() =>
  import("./components/pages/events/StaffSingleEvent.jsx"),
);
const EventCreate = React.lazy(() =>
  import("./components/pages/events/Create.jsx"),
);
const EventEdit = React.lazy(() =>
  import("./components/pages/events/Edit.jsx"),
);

// ID Card Service
const Idcard = React.lazy(() => import("./components/pages/idcard/View.jsx"));

// Food Cupboard
const FoodCupboard = React.lazy(() =>
  import("./components/pages/foodCupboard/View.tsx"),
);

const Reports = React.lazy(() => import("./components/pages/reports/View"));

// withdrawal application
const Withdrawal = React.lazy(() =>
  import("./components/pages/withdrawal/View"),
);
const WithdrawalApplication = React.lazy(() =>
  import("./components/pages/withdrawal/Application"),
);
const SingleWithdrawal = React.lazy(() =>
  import("./components/pages/withdrawal/SingleApplication"),
);

// completion application
const Completion = React.lazy(() =>
  import("./components/pages/completion/View"),
);

const SingleCompletion = React.lazy(() =>
  import("./components/pages/completion/SingleCompletion"),
);

const Coop = React.lazy(() => import("./components/pages/coop/View"));

const Application = React.lazy(() =>
  import("./components/pages/coop/Application"),
);
const EmergencyContactForm = React.lazy(() =>
  import("./components/pages/coop/EmergencyContactForm"),
);
const CoopPolicy = React.lazy(() =>
  import("./components/pages/coop/CoopPolicy"),
);
const ApplicationQA = React.lazy(() =>
  import("./components/pages/coop/ApplicationQA"),
);

// aip
const Aip = React.lazy(() => import("./components/pages/aip/View"));
const AipView = React.lazy(() => import("./components/pages/aip/SingleView"));

// employers list
const Employers = React.lazy(() => import("./components/pages/employers/View"));

// convocation
const Convo = React.lazy(() => import("./components/pages/convocation/View"));
const ConvoForm = React.lazy(() =>
  import("./components/pages/convocation/student/Form"),
);

// hotel accommodation
const Hotel = React.lazy(() => import("./components/pages/hotel/View"));

// !Not active currenty -- 12/29/2020
const QuarantineRoutes = React.lazy(() =>
  import("./services/QuarantinePackage/Containers/Routes"),
);

// Non blocking import for CSS
(async function () {
  await import("rsuite/dist/rsuite.min.css");
})();

dayjs.extend(utc);
dayjs.extend(Timezone);
dayjs.tz.setDefault("Asia/Seoul");

const App = () => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 768;

  // Check the user authentication
  useEffect(() => {
    store.dispatch(loadUser());
    // show the pop up from here , if the token is about to expire
    // and user wants to increase their session
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <TokenExtend />

        <React.Suspense fallback={<Loading />}>
          <Router>
            <PrivateView accessService="privatePublicAll">
              {width && breakpoint && width > breakpoint ? (
                <NavBar />
              ) : (
                <MobileNavBar />
              )}
              {/* <NavBar /> */}
            </PrivateView>
            <Alerts />
            <Switch>
              <PrivateRoute exact path="/Empty" component={EmptyPage} />
              <ProtectedRoute exact path="/" component={Dashboard} />

              {/* Auth Routes  */}
              <Route path="/login" component={Login} />
              <Route path="/stafflogin" component={Login} />
              <Route path="/register" component={Register} />

              <Route
                exact
                accessService={"orientation"}
                path="/idcard/:name/:code/:ext"
                component={DocumentLink}
              />
              {/* Registration Routes */}
              <Route path="/orientation" component={Orientation} />
              <ProtectedRoute path="/ict" component={IctPolicy} />

              <ProtectedRoute
                path="/profile"
                accessService={"profile"}
                component={ProfileRoutes}
              />

              {/* immigration doc form */}
              <PrivateRoute
                path="/documents"
                claimWithPermission={[0, 1, 2]}
                claimWithDepartment={"Orientation"}
                component={DocumentRoute}
              />

              {/* Quarantine Routes */}
              {/* <PrivateRoute path="/quarantine" accessService={'privatePublic'} component={Quarantine} /> */}

              {/* Group routes */}
              <PrivateRoute path="/groups" component={GroupRoutes} />
              <PrivateRoute
                path="/selected/groups"
                component={GroupSelectionStudentList}
              />
              <PrivateRoute
                path="/selected/student/:id"
                component={GroupSelectionDetails}
              />

              {/* Schedules routes */}
              <PrivateRoute
                path="/schedules"
                accessService={"approvedStudents"}
                component={Schedules}
              />

              {/* Create Student */}
              <PrivateRoute
                path="/createStudents"
                accessService={"orientation"}
                exact
                component={CreateStudents}
              />

              {/* Retakes routes */}
              <ProtectedRoute exact path="/retake" component={Retake} />
              <ProtectedRoute
                exact
                path="/retake/apply"
                component={ApplyRetake}
                claimWithPermission={[1]}
              />
              <ProtectedRoute
                exact
                path="/retake/:id"
                component={ViewRetake}
                claimWithPermission={[0, 2]}
                claimWithDepartment="Orientation"
                claimWithRole="admin"
              />
              <ProtectedRoute
                exact
                path="/retake/single/:id"
                component={StudentRetake}
                claimWithPermission={[1]}
              />

              <PrivateRoute
                path="/accessManager"
                accessService={"superOnly"}
                component={Orientation}
              />
              <PrivateRoute
                path="/createUser"
                accessService={"superOnly"}
                component={Orientation}
              />

              {/* ⛔️ Removing documents feature until needed   */}
              <PrivateRoute
                accessService="allAdminsNdSuper"
                path="/documents/studentlist"
                component={StudentDocumentList}
              />
              <PrivateRoute
                accessService="approvedStudents"
                path="/documents/list"
                component={SubmittedDocumentsList}
              />
              <PrivateRoute
                accessService="approvedStudents"
                path="/documents/dashboard"
                component={StudentDashboard}
              />
              <PrivateRoute
                accessService="approvedStudents"
                path="/documents/pendingdocuments"
                component={PendingDocuments}
              />

              {/* Routes for all students collection */}
              <PrivateRoute
                path="/students/list"
                accessService={"orientation"}
                component={StudentList}
              />
              <PrivateRoute
                accessService={"orientation"}
                path="/students/view/:id"
                component={StudentView}
              />
              <PrivateRoute
                accessService={"orientation"}
                path="/students/edit/:id"
                component={StudentEditPage}
              />

              <PrivateRoute
                path="/students/groupEligibilityList"
                component={GroupEligibilityList}
              />

              <PrivateRoute
                path="/settings/users"
                accessService={"superOnly"}
                component={UsersSettings}
              />

              <Route path="/quarantine" component={QuarantineRoutes} />

              {/* Academic routes */}
              <PrivateRoute
                path="/courses"
                accessService={"superOnly"}
                component={Courses}
              />
              <PrivateRoute
                path="/programs"
                accessService={"superOnly"}
                component={Programs}
              />
              <PrivateRoute
                path="/classes"
                accessService={"superOnly"}
                component={Classes}
              />

              {/* Intakes  */}
              <PrivateRoute
                path="/settings/intakes"
                accessService={"superOnly"}
                component={Intakes}
              />
              <PrivateRoute
                path="/settings/postSchedules"
                accessService={"superOnly"}
                component={PostScheduleSettings}
              />

              <PrivateRoute
                path="/settings/preSchedules"
                accessService={"superOnly"}
                component={PreScheduleSettings}
              />
              <PrivateRoute
                path="/settings/departments"
                accessService={"superOnly"}
                component={DepartmentSettings}
              />

              {/* Bus Pass*/}
              <PrivateRoute
                path="/shuttlepass"
                accessService={"privatePublic"}
                component={BusPassView}
              />
              <PrivateRoute
                path="/purchase"
                accessService={"privatePublic"}
                component={Purchase}
              />

              {/* Events */}
              <ProtectedRoute
                exact
                path="/events"
                component={Events}
                claimWithPermission={[0, 1, 2]}
                claimWithRole={"Member"}
                claimWithDepartment={"Events"}
              />
              <ProtectedRoute
                exact
                path="/events/create"
                claimWithPermission={[0, 2]}
                claimWithRole={"admin"}
                claimWithDepartment={"Events"}
                component={EventCreate}
              />
              <ProtectedRoute
                exact
                path="/events/edit/:id"
                claimWithPermission={[0, 2]}
                claimWithRole={"admin"}
                claimWithDepartment={"Events"}
                component={EventEdit}
              />
              <ProtectedRoute
                exact
                path="/events/:id"
                claimWithPermission={[0, 2]}
                claimWithRole={"Member"}
                claimWithDepartment={"Events"}
                component={StaffSingleView}
              />
              <ProtectedRoute
                exact
                path="/events/single/:id"
                component={SingleView}
                claimWithPermission={[1]}
              />

              {/* ID Cards */}
              <PrivateRoute
                path="/idcard"
                accessService={"privatePublic"}
                component={Idcard}
              />

              {/* Food Cupboard */}
              <PrivateRoute
                path="/food-basket"
                accessService={"privatePublic"}
                component={FoodCupboard}
              />

              {/* Reports */}
              {/* <PrivateRoute
                path="/reports"
                accessService={"career"}
                component={Reports}
              /> */}
              <ProtectedRoute
                path="/reports"
                claimWithPermission={[0, 2]}
                component={Reports}
              />

              {/* coop */}
              <ProtectedRoute
                exact
                path="/coop"
                component={Coop}
                claimWithPermission={[0, 1, 2]}
                claimWithDepartment={"Career"}
              />

              <ProtectedRoute
                exact
                path="/coop/policy/:id"
                claimWithPermission={[1]}
                component={CoopPolicy}
              />

              <ProtectedRoute
                exact
                path="/coop/form/:id"
                claimWithPermission={[0, 1, 2]}
                claimWithDepartment={"Career"}
                claimWithRole={"admin"}
                component={EmergencyContactForm}
              />

              <ProtectedRoute
                exact
                path="/coop/QA/:id"
                claimWithPermission={[2]}
                claimWithDepartment={"Career"}
                claimWithRole={"Member"}
                component={ApplicationQA}
              />

              <ProtectedRoute
                exact
                path="/coop/:id"
                claimWithPermission={[0, 2]}
                claimWithDepartment={"Career"}
                claimWithRole={"admin"}
                component={Application}
              />

              {/* employers */}

              <ProtectedRoute
                exact
                path="/employers"
                claimWithPermission={[0, 2]}
                claimWithDepartment={"Career"}
                claimWithRole={"admin"}
                component={Employers}
              />

              {/* Withdrawal */}
              <ProtectedRoute
                exact
                path="/withdrawal"
                claimWithPermission={[0, 1, 2, 3]}
                claimWithDepartment={"Orientation"}
                component={Withdrawal}
              />

              <ProtectedRoute
                exact
                path="/withdrawal/application"
                claimWithPermission={[1, 3]}
                component={WithdrawalApplication}
              />

              <ProtectedRoute
                exact
                path="/withdrawal/:id"
                claimWithPermission={[0, 2]}
                claimWithDepartment={"Orientation"}
                component={SingleWithdrawal}
              />

              {/* completion letter */}
              <ProtectedRoute
                exact
                path="/completion"
                claimWithPermission={[0, 1, 2]}
                claimWithDepartment={"Orientation"}
                component={Completion}
              />

              <ProtectedRoute
                exact
                path="/completion/:id"
                claimWithPermission={[0, 2]}
                claimWithDepartment={"Orientation"}
                component={SingleCompletion}
              />

              {/* convocation */}
              <ProtectedRoute
                exact
                path="/convocation"
                claimWithPermission={[0, 1, 2]}
                claimWithDepartment={"Orientation"}
                component={Convo}
              />

              <ProtectedRoute
                exact
                path="/convocation/form"
                claimWithPermission={[1]}
                component={ConvoForm}
              />

              {/* AIP */}
              <ProtectedRoute
                exact
                path="/aip/view/:id"
                claimWithPermission={[0, 2]}
                claimWithDepartment={"Aip"}
                claimWithRole={"admin"}
                component={AipView}
              />
              <ProtectedRoute
                path="/aip"
                claimWithPermission={[0, 1, 2]}
                claimWithDepartment={"Aip"}
                component={Aip}
              />

              <ProtectedRoute
                exact
                path="/hotel"
                claimWithPermission={[0, 1, 2, 3]}
                component={Hotel}
              />

              {/* All random pages */}
              <Route path="/*" component={PageNotFound} />
            </Switch>
          </Router>
        </React.Suspense>
      </Provider>
      {config.REACT_APP_RQ_ENV === "development" ? (
        <ReactQueryDevtools />
      ) : null}
    </QueryClientProvider>
  );
};

export default App;
