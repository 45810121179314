import { useUser } from "../api/user";

type TDepartment = {
  _id: string;
  name: string;
  role: "admin" | "Member";
};

type TResult = {
  isAllowed: boolean;
  isLoading: boolean;
};

/**
 * Check does the user ( from store ) has permission to access
 * @param allowedPermission permission include 0,1,2,3
 * @param allowedDepartment (optional) if all staff able to access, do not need to pass in any value
 * @param allowedRole  (optional) a list of role that is allowed. The role consists of {deparment:role}.
 * only 1 deparment is allow to access. This is required when staff is included in allowedPermission
 * @returns boolean
 */
export const useHasPermission = (
  allowedPermission: number[],
  allowedDepartment?: string,
  allowedRole?: string,
): TResult => {
  const { data: currentUser, isLoading: isUserLoading } = useUser();
  let isLoading = isUserLoading;
  let isAllowed = false;

  // check what permission does the user has
  if (!isUserLoading) {
    isLoading = isUserLoading;
    const userPermission: number = currentUser?.permission as number;
    const userDepartments = currentUser?.departments;

    // if no permission is given then just return true
    if (
      allowedPermission.length === 0 &&
      allowedDepartment === undefined &&
      allowedRole === undefined
    ) {
      return { isAllowed: true, isLoading };
    }

    // if the user permission is not in the permission list
    if (!allowedPermission.includes(userPermission)) {
      return { isAllowed: false, isLoading };
    }

    // if not a staff, just return true
    if (userPermission !== 2) {
      return { isAllowed: true, isLoading };
    }

    // staff but no required department - all staff can access it
    if (allowedDepartment === undefined && allowedRole === undefined) {
      return { isAllowed: true, isLoading };
    }

    // there is department
    const dept = userDepartments?.filter(
      (d) => d.name === allowedDepartment,
    ) as TDepartment[];
    if (dept?.length === 0) {
      isAllowed = false;
    } else {
      if (dept[0].role !== allowedRole) {
        if (dept[0].role === "Member" && allowedRole === "admin") {
          isAllowed = false;
        } else {
          isAllowed = true;
        }
      } else {
        // role match the allowed role
        isAllowed = true;
      }
    }
  }

  return { isAllowed, isLoading };
};
