import mainAxios from "../axios/Axios";
import fileDownload from "./file-download";

const mimeType = (ext) => {
  switch (ext) {
    case "png":
      return "image/png";
    case "jpg":
      return "image/jpg";
    case "jpeg":
      return "image/jpeg";
    case "pdf":
      return "application/pdf";
    case "zip":
      return "application/zip";
  }
};

// download a file
async function downloadFile(fileInfo, name) {
  //if no name is provided, use name from file info
  const fileName = typeof name !== "undefined" ? name : fileInfo.name;
  const report = await mainAxios.post(
    `/documents/download`,
    { fileInfo },
    { responseType: "blob" },
  );
  fileDownload(report.data, fileName, mimeType(fileInfo.ext));
}

export default downloadFile;
