import { GET_PROGRAMS } from "../actions/types";

const initialState = {
  loading: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PROGRAMS:
      return {
        ...state,
        ...payload,
        loading: false,
      };

    default:
      return state;
  }
};
