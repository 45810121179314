import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, SelectPicker, Form } from "rsuite";
import LegacyPlusSquareIcon from "@rsuite/icons/legacy/PlusSquare";
import LegacyMinusSquareIcon from "@rsuite/icons/legacy/MinusSquare";
import { getDepartments } from "../../../actions/settings.js";
import { sendNotificationToStudent } from "../../../actions/documents";
import { toaster } from "evergreen-ui";

const AlertStudentModal = ({
  onModalClose,
  getDepartments,
  departments,
  staffDepartments,
  documentsData,
  sendNotificationToStudent,
}) => {
  const [state, setState] = useState({
    studentId: "",
    department: "",
    documents: [""],
    other: "",
  });

  const [documentsArray, setDocumentsArray] = useState([]);

  useEffect(() => {
    getDepartments();

    const documents = documentsData.filter((item) => {
      return item.department === state.department;
    });
    const documentsArr = documents[0] ? documents[0].documents : [];
    setDocumentsArray(documentsArr);
  }, [state.department]);

  console.log(departments);

  const onChange = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const onDocumentChange = (index, key, value) => {
    const newDocument = [...state.documents];
    newDocument[index] = { ...newDocument[index], [key]: value };
    setState({ ...state, documents: [...newDocument] });
  };

  //** */ if you change the selected department , the use Effect will run and
  //** */ make the selected document types  to empty array again

  const addField = () => {
    let newArr = [...state.documents];
    newArr.push("");
    setState({ ...state, documents: [...newArr] });
  };

  const deleteField = (index) => {
    let newArr = [...state.documents];
    newArr.splice(index, 1);
    setState({ ...state, documents: [...newArr] });
  };

  return (
    <div>
      <Form>
        <Form.Group>
          <Form.ControlLabel> Student Id :</Form.ControlLabel>
          <Form.Control
            placeholder="Enter student Id "
            value={state.studentId}
            onChange={(value) => onChange("studentId", value)}></Form.Control>
        </Form.Group>

        <Form.Group>
          <Form.ControlLabel> Select Department :</Form.ControlLabel>
          <SelectPicker
            style={{ width: "300px" }}
            placeholder="Select Department"
            value={state.department}
            onChange={(value) => onChange("department", value)}
            data={
              staffDepartments.length == 0
                ? departments.map((item) => {
                    return { label: item.name, value: item.name };
                  })
                : staffDepartments.map((item) => {
                    return { label: item.name, value: item.name };
                  })
            }
          />
        </Form.Group>

        <Form.Group>
          <Form.ControlLabel>
            {" "}
            Select Document Type
            <LegacyPlusSquareIcon
              style={{
                color: "darkblue",
                cursor: "pointer",
                fontSize: "1.2rem",
                position: "relative",
                top: "3px",
                left: "5px",
              }}
              onClick={() => addField()}
            />
          </Form.ControlLabel>
          {state.documents.map((item, index) => {
            return (
              <>
                <SelectPicker
                  style={{ width: 300, marginBottom: "5px" }}
                  placeholder="Select Document"
                  value={state.documents[index].document}
                  onChange={(value) =>
                    onDocumentChange(index, "document", value)
                  }
                  data={documentsArray.map((item) => {
                    return { label: item, value: item };
                  })}
                />
                <LegacyMinusSquareIcon
                  onClick={() => deleteField(index)}
                  style={{
                    fontSize: "1.5rem",
                    marginLeft: "10px",
                    position: "relative",
                    top: "3px",
                    color: "darkred",
                    cursor: "pointer",
                  }}
                />
              </>
            );
          })}
        </Form.Group>

        {/* Submit button for testing the documents values choosen  */}
        <Button
          appearance="primary"
          style={{ position: "relative", top: "10px" }}
          onClick={async () => {
            // close the modal and send data to redux store and call api there.

            const status = await sendNotificationToStudent(state);

            if (status === 200) {
              onModalClose("student", false);
              toaster.success("The request has been sent successfully !", {
                duration: 3,
              });
            }
          }}
        >
          Submit
        </Button>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  departments: state.settings.departments,
  staffDepartments: state.auth.user.departments,
  documentsData: state.settings.documentsData,
});

export default connect(mapStateToProps, {
  getDepartments,
  sendNotificationToStudent,
})(AlertStudentModal);
