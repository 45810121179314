import React from "react";
import { Link, withRouter } from "react-router-dom";

// Import the UI components
import { Button, Card, Text, Heading, Pane } from "evergreen-ui";

const Empty = ({
  img,
  title,
  message,
  buttonLink,
  buttonValue,
  imageSize = 200,
  link,
  buttonEnabled = true,
}) => {
  return (
    <Pane display="flex" alignContent="center" justifyItems="center">
      <Card
        className="card-default"
        elevation={1}
        style={{ top: "40px", zIndex: 0 }}
      >
        <img width={imageSize} src={img} />
        <div className="card-content">
          <Heading size={600}>{title}</Heading>
          <Text>{message}</Text>
        </div>

        {link ? (
          <>
            {buttonEnabled && (
              <a href={link}>
                <Button appearance="primary">{buttonValue}</Button>
              </a>
            )}
          </>
        ) : (
          <>
            {buttonEnabled && (
              <a href={`mailto:${buttonLink}`}>
                <Button appearance="primary">{buttonValue}</Button>
              </a>
            )}
          </>
        )}
      </Card>
    </Pane>
  );
};

export default Empty;
