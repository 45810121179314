import React from "react";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Navbar, Nav, Panel, Avatar, Dropdown } from "rsuite";

// Redux
import { logout } from "../../actions/auth";
import { useNavigationData } from "../../hooks/useNavigationData";
import Loading from "./Loading/Loading";

const NavBar = ({ logout, user, navigationData, history, props }) => {
  const location = useLocation();
  const { pathname } = location;
  const { navData, isNavLoading } = useNavigationData();
  // get the user
  let name = "";

  // call the navigation data hook here to get the navigation data

  if (user) name = user.firstName[0] + " " + user.lastName[0];

  if (isNavLoading) {
    return <Loading />;
  }

  return (
    <>
      <Panel style={{ overflow: "visible" }}>
        <div style={{ margin: "0 5%", marginLeft: "7.6%", marginRight: "4%" }}>
          <Navbar appearance="subtle">
            <Navbar.Brand style={{ marginTop: "-15px" }} href="/">
              {/* <a href="/" className="navbar-brand logo"> */}
              <img width="230" height="100" src="/img/logo.png" />
              {/* </a> */}
            </Navbar.Brand>
            <Nav pullRight>
              {/* the nav content*/}

              {/*  0 dont show, 1 show the link, more than 1 will be dropdown  */}
              {!isNavLoading &&
                Object.keys(navData).map((navGroup, index) =>
                  navData[navGroup].length <= 1 ? (
                    // non group nav item
                    navData[navGroup].length === 0 ? (
                      ""
                    ) : (
                      <Nav.Item
                        href={navData[navGroup][0].path}
                        key={index}
                        active={navData[navGroup][0].path === pathname}
                      >
                        {navData[navGroup][0].label}
                      </Nav.Item>
                    )
                  ) : (
                    <Nav.Menu trigger="hover" title={navGroup} key={index}>
                      {navData[navGroup].map((item, i) => (
                        <NavLink to={item.path} key={i}>
                          <Nav.Item active={item.path === pathname}>
                            {item.label}
                          </Nav.Item>
                        </NavLink>
                      ))}
                    </Nav.Menu>
                  ),
                )}

              {/* profile  */}
              <Nav.Menu
                key="profile-dropdown"
                trigger="hover"
                title={
                  <div>
                    <span style={{ marginRight: "5px" }}>{name}</span>{" "}
                    <Avatar size="sm" circle>
                      {name[0]}
                    </Avatar>
                  </div>
                }
              >
                <Nav.Item
                  onSelect={async () => {
                    await logout();
                    history.push("/");
                  }}
                  style={{ width: "150px" }}
                >
                  Logout
                </Nav.Item>
                {user.permission === 1 ? (
                  <Nav.Item
                    onSelect={() =>
                      history.push(`/profile/view/${user._id[0]}`)
                    }
                  >
                    {" "}
                    Profile
                  </Nav.Item>
                ) : (
                  ""
                )}
              </Nav.Menu>
            </Nav>
          </Navbar>
        </div>
      </Panel>
    </>
  );
};

NavBar.propTypes = {
  logout: PropTypes.func.isRequired,
  // auth: PropTypes.object.isRequired,
};

const mapStateToProp = (state) => ({
  user: state.auth.user,
  navigationData: state.settings.navigationData,
});

export default withRouter(connect(mapStateToProp, { logout })(NavBar));
