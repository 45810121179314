import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import mainAxios from "../../../axios/Axios";
import { Modal, Row, Col, Grid, Button } from "rsuite";
import LegacyFilterIcon from "@rsuite/icons/legacy/Filter";
import StudentList from "./studentlist";
import Loading from "../../../components/layout/Loading/Loading";

import {
  getPrograms,
  getIntakes,
  getDepartments,
} from "../../../actions/settings";

import { getClasses } from "../../../actions/classes";

import {
  Table,
  IconButton,
  Heading,
  Button as eButtton,
  Menu,
  toaster,
  Popover,
  Position,
} from "evergreen-ui";

import AlertStudentModal from "./alertStudentModal";
import AlertClassModal from "./alertClassModal";
import AttachDocumentModal from "./attachDocumentModal";

// lets use redux here to make the layout simpler
// all the api calls will be in redux
const StudentListContainer = ({
  history,
  getPrograms,
  programs,
  getIntakes,
  intakes,
  getDepartments,
  classes,
  getClasses,
}) => {
  const [list, setListData] = useState([]);
  const [filter, setFilter] = useState({
    studentId: "",
    type: "",
    page: 0,
  });

  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const [modalUi, setModalUi] = useState({
    student: false,
    class: false,
    attch: false,
  });

  useEffect(() => {
    fetchfilterList();
    getPrograms();
    getIntakes();
    getDepartments();
    getClasses();
  }, [filter.page]);

  const fetchfilterList = () => {
    setLoading(true);

    let query = "";
    const staticFields = new Set(["page", "studentId", "type", "department"]);

    Object.keys(filter).forEach((key) => {
      if (staticFields.has(key) && !!filter[key]) {
        query = query + `${key}=${filter[key]}&`;
      }
    });

    mainAxios.get(`/documents/filter/list?${query}`).then((res) => {
      setLoading(false);
      setListData(res.data.docs);
      setTotalCount(res.data.totalDocs);
    });
  };

  const onfilterChange = (key, value) => {
    setFilter({ ...filter, [key]: value });
  };

  const onModalClose = (key, value) => {
    setModalUi({ ...modalUi, [key]: value });
  };

  return (
    <Grid fluid style={{ width: "80%", margin: "0px auto", marginTop: "20px" }}>
      {/* Notify student modal */}
      <Modal
        style={{ width: "70%", margin: "40px auto" }}
        size="full"
        open={modalUi.student}
        onClose={() => setModalUi({ ...modalUi, student: false })}
      >
        <Modal.Header>
          <Modal.Title> Notify a Student </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertStudentModal onModalClose={onModalClose} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setModalUi({ ...modalUi, student: false })}
            appearance="subtle"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* atttach a document to a student's profile modal */}
      <Modal
        style={{ width: "70%", margin: "40px auto", zIndex: 10 }}
        size="full"
        open={modalUi.attach}
        onClose={() => setModalUi({ ...modalUi, attach: false })}
      >
        <Modal.Header>
          <Modal.Title> Attach a Document to Student's profile </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AttachDocumentModal
            onModalClose={onModalClose}
            fetchfilterList={fetchfilterList}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setModalUi({ ...modalUi, attach: false })}
            appearance="subtle"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Notify a class modal  */}
      <Modal
        style={{ width: "70%", margin: "40px auto" }}
        size="full"
        open={modalUi.class}
        onClose={() => setModalUi({ ...modalUi, class: false })}
      >
        <Modal.Header>
          <Modal.Title> Notify the whole class </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertClassModal onModalClose={onModalClose} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setModalUi({ ...modalUi, class: false })}
            appearance="subtle"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {list ? (
        <>
          <Row className="show-grid">
            <Col xs={24} style={{ marginTop: "20px", marginBottom: "40px" }}>
              <h3
                style={{
                  marginBottom: "10px",
                  display: "inline-block",
                  color: "#234361",
                }}
              >
                {" "}
                Documents{" "}
              </h3>
              <Button
                appearance="primary"
                style={{
                  float: "right",
                  marginRight: "47px",
                  marginTop: "10px",
                  color: "black9",
                }}
                onClick={() => {
                  fetchfilterList();
                  setFilter({ ...filter, page: 0 });
                }}
              >
                <LegacyFilterIcon
                  style={{ marginRight: "5px", display: "inline" }}
                />{" "}
                Apply Filter
              </Button>

              <Popover
                position={Position.BOTTOM_LEFT}
                content={
                  <Menu>
                    <Menu.Group>
                      <Menu.Item
                        onSelect={() =>
                          setModalUi({ ...modalUi, attach: true })
                        }
                        secondaryText="&#9998;"
                      >
                        Attach a Document
                      </Menu.Item>
                      <Menu.Item
                        onSelect={() =>
                          setModalUi({ ...modalUi, student: true })
                        }
                        secondaryText="&#9993;"
                      >
                        Alert a Student
                      </Menu.Item>
                      <Menu.Item
                        onSelect={() => setModalUi({ ...modalUi, class: true })}
                        secondaryText="&#9993;"
                      >
                        Alert a Class
                      </Menu.Item>
                    </Menu.Group>
                    <Menu.Divider />
                  </Menu>
                }
              >
                <IconButton
                  is="a"
                  style={{
                    width: "36px",
                    height: "36px",
                    position: "absolute",
                    right: 0,
                    top: "10px",
                  }}
                  width={0}
                  icon="cog"
                  intent="primary"
                />
              </Popover>

              {/* <Button appearance='ghost' style={{ float: "right", marginRight: "20px", marginTop: "10px", color: "black9" }} onClick={() => setModalUi(true)} > <Icon style={{ marginRight: '5px' }} icon='file-excel-o' /> Generate Reports</Button> */}
            </Col>
          </Row>

          <StudentList
            page={filter.page}
            lists={list}
            filter={filter}
            history={history}
            loading={loading}
            totalCount={totalCount}
            fetchfilterList={fetchfilterList}
            onfilterChange={onfilterChange}
            intakes={
              intakes &&
              intakes.map((item, index) => {
                return { label: item.name, value: item.name };
              })
            }
          />
        </>
      ) : (
        <Loading />
      )}
    </Grid>
  );
};

const mapPropsToState = (store) => {
  return {
    programs: store.settings.programs,
    intakes: store.settings.intakes,
    classes: store.settings.classes,
  };
};

export default connect(mapPropsToState, {
  getPrograms,
  getIntakes,
  getDepartments,
  getClasses,
})(StudentListContainer);
