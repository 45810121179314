import {
  GET_DOCUMENTS,
  SET_DOCUMENTS_LOADING,
  PENDING_DOCUMENTS,
  GET_SELECTED_DOCUMENT,
  GET_STAFF_SELECTED_DOCUMENT,
  GET_IMMIGRATION_DOCUMENT_LIST,
  GET_SELECTED_IMMIGRATION_DOCUMENTS,
  GET_DOCUMENT_STATUS_LIST,
} from "../actions/types";

const initialState = {
  loading: false,
  pendingList: [],
  selectedDocuments: [],
  staffSelectedDocuments: [],
  immigrationDocumentList: [],
  selectedImmigrationDocuments: [],
  documentStatusList: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_DOCUMENTS:
      return {
        ...state,
        ...payload,
        loading: false,
      };

    case SET_DOCUMENTS_LOADING:
      return {
        ...state,
        loading: payload,
      };

    case PENDING_DOCUMENTS:
      return {
        ...state,
        pendingList: payload,
        loading: false,
      };

    case GET_SELECTED_DOCUMENT:
      return {
        ...state,
        selectedDocuments: payload,
      };

    case GET_STAFF_SELECTED_DOCUMENT:
      return {
        ...state,
        staffSelectedDocuments: payload,
      };

    case GET_IMMIGRATION_DOCUMENT_LIST:
      return {
        ...state,
        immigrationDocumentList: payload,
      };

    case GET_SELECTED_IMMIGRATION_DOCUMENTS:
      return {
        ...state,
        selectedImmigrationDocuments: payload,
      };

    case GET_DOCUMENT_STATUS_LIST:
      return {
        ...state,
        documentStatusList: payload,
      };
    default:
      return state;
  }
};
