import React from "react";
import PageNotFoundImg from "../../../assets/images/fileNotFound.svg";

class PageNotFound extends React.PureComponent {
  render() {
    return (
      <div>
        <div style={{ textAlign: "center" }}>
          <h1 style={{ textAlign: "center" }}>Page Not Found!</h1>
          <img
            src={PageNotFoundImg}
            style={{ width: "50%", marginTop: "30px" }}
            alt="page not found"
          />
        </div>
      </div>
    );
  }
}

export default PageNotFound;
