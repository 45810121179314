import { GET_COURSES, SET_COURSE_LOADING } from "../actions/types";

const initialState = {
  loading: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_COURSES:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case SET_COURSE_LOADING:
      return {
        ...state,
        loading: payload,
      };
    default:
      return state;
  }
};
