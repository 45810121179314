import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { Panel, Button, SelectPicker, Form } from "rsuite";

import { getDepartments } from "../../../actions/settings.js";
import {
  submitAttachedDocument,
  getStaffSelectedDocument,
} from "../../../actions/documents";

import { toaster, Alert } from "evergreen-ui";

import Dropzone from "./staffDropzone";

const AttachDocumentModal = ({
  onModalClose,
  documentsData,
  fetchfilterList,
  user,
  staffDepartments,
  departments,
  staffSelectedDocuments,
  submitAttachedDocument,
  getStaffSelectedDocument,
}) => {
  // we need studentId, department ,

  const [state, setState] = useState({
    studentId: null,
    department: null,
    type: null,
    other: "",
  });

  const [documentsArray, setDocumentsArray] = useState([]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    getDepartments();

    const documents = documentsData.filter((item) => {
      return item.department === state.department;
    });
    const documentsArr = documents[0] ? documents[0].documents : [];
    setDocumentsArray(documentsArr);
  }, [state.department]);

  useEffect(() => {
    if (visible) {
      // getStaffSelectedDocument(state)
    }
  }, [visible]);

  const onChange = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const onDocumentChange = (index, key, value) => {
    const newDocument = [...state.documents];
    newDocument[index] = { ...newDocument[index], [key]: value };
    setState({ ...state, documents: [...newDocument] });
  };

  //** */ if you change the selected department , the use Effect will run and
  //** */ make the selected document types  to empty array again

  return (
    <div>
      <Form fluid>
        <Form.Group>
          <Form.ControlLabel> Student Id :</Form.ControlLabel>
          <Form.Control
            style={{ width: "100%", display: "inline-block" }}
            value={state.studentId}
            placeholder="Enter student Id "
            onChange={(value) => {
              onChange("studentId", value);
              setVisible(false);
            }}
          ></Form.Control>
        </Form.Group>

        {state.studentId ? (
          <Form.Group>
            <Form.ControlLabel> Select Department :</Form.ControlLabel>
            <SelectPicker
              style={{ width: "100%" }}
              placeholder="Select Department"
              value={state.department}
              onChange={(value) => {
                // onChange("department", value)
                // onChange("type",null)
                setState({ ...state, department: value, type: null });
                setVisible(false);
              }}
              data={
                staffDepartments.length == 0 || user.permission == 0
                  ? departments.map((item) => {
                      return { label: item.name, value: item.name };
                    })
                  : staffDepartments.map((item) => {
                      return { label: item.name, value: item.name };
                    })
              }
            />
          </Form.Group>
        ) : (
          " "
        )}

        {state.studentId ? (
          <Form.Group>
            <Form.ControlLabel> Select Document Type : </Form.ControlLabel>
            <SelectPicker
              style={{ width: "100%", marginBottom: "5px" }}
              placeholder="Select Document Type"
              value={state.type}
              onChange={(value) => {
                onChange("type", value);
                setVisible(false);
              }}
              data={documentsArray.map((item) => {
                return { label: item, value: item };
              })}
            />
          </Form.Group>
        ) : (
          " "
        )}

        {state.type !== null && state.studentId ? (
          <Form.Group>
            <Form.ControlLabel marginBottom={"5px"}>
              {" "}
              Please upload your selected Document here:
            </Form.ControlLabel>
            {/* <PanelGroup  bordered onSelect={()=> setVisible(true)} expanded={true} > */}
            <Panel
              bordered
              onSelect={() => {
                !visible ? setVisible(true) : setVisible(false);
              }}
              expanded={true}
              header={"Click here to submit the Document +"}
              className={"immigrationDoc__header"}
            >
              {visible === true ? (
                <Dropzone
                  styleName={"fileUpload"}
                  studentId={state.studentId}
                  type={state.type}
                  department={state.department}
                  data={state.type}
                  getStaffSelectedDocument={getStaffSelectedDocument}
                  deleteUrl={"/documents"}
                  getUrl={"/documents"}
                  postUrl={"/documents"}
                />
              ) : (
                ""
              )}
            </Panel>
            {/* </PanelGroup> */}
          </Form.Group>
        ) : (
          ""
        )}

        {/* Submit button for testing the documents values choosen  */}

        <Button
          appearance="primary"
          style={{ position: "relative", top: "10px" }}
          onClick={async () => {
            // close the modal and send data to redux store and call api there.

            if (staffSelectedDocuments.length > 0) {
              const res = await submitAttachedDocument(state);

              if (res && res.status === 200) {
                onModalClose("attach", false);

                toaster.success(
                  "The document has been attached successfully !",
                  { duration: 4 },
                );
                // window.location.reload(true)
                fetchfilterList();
              }
            } else {
              toaster.danger("Cannot submit form without the document !");
            }
          }}
        >
          Submit
        </Button>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  departments: state.settings.departments,
  staffDepartments: state.auth.user.departments,
  documentsData: state.settings.documentsData,
  staffSelectedDocuments: state.documents.staffSelectedDocuments,
});

export default connect(mapStateToProps, {
  getDepartments,
  submitAttachedDocument,
  getStaffSelectedDocument,
})(AttachDocumentModal);
