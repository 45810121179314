import {
  SET_RETAKE,
  RETAKE_ERROR,
  GET_RETAKES,
  RETAKE_LOADING,
  GET_SCHEDULES,
} from "../actions/types.js";
const initialState = {
  list: [],
  retakeCount: [],
  retake: {},
  loading: true,
  error: false,
  schedules: [],
};

export default (state = initialState, action) => {
  // Get the type and payload
  const { type, payload } = action;

  switch (type) {
    case SET_RETAKE:
      return { ...state, retake: payload, loading: false, error: false };
    case GET_RETAKES:
      return {
        ...state,
        list: payload.totalData,
        retakeCount: payload.totalCount,
        loading: false,
      };
    case RETAKE_ERROR:
      return { ...state, error: payload, loading: false };
    case RETAKE_LOADING:
      return { ...state, loading: payload };
    case GET_SCHEDULES:
      return { ...state, schedules: payload, loading: false };
    default:
      return state;
  }
};
