import { GET_PASSES, GET_STUDENT_PASSES } from "../actions/types";

const initialState = {
  busPasses: [],
  totalCount: 0,
  loading: true,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    // totalCount is an empty array when there are no results
    // otherwise totalCount: [{count: <number>}]
    case GET_PASSES: {
      return {
        ...state,
        busPasses: payload.totalData,
        totalCount: payload.totalCount[0] && payload.totalCount[0].count,
        loading: false,
      };
    }
    case GET_STUDENT_PASSES: {
      return {
        ...state,
        busPasses: payload,
        loading: false,
      };
    }

    default:
      return { ...state };
  }
};
