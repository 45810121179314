import { GET_CLASSES, GET_CLASSES_FILTERED_LIST } from "../actions/types";

const initialState = {
  loading: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CLASSES:
      return {
        ...state,
        ...payload.classes,
      };
    case GET_CLASSES_FILTERED_LIST:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
