import defaultAxios from "../axios/Axios";

import {
  GET_SETTINGS,
  SET_INTAKES,
  SET_PROGRAMS,
  SET_DEPARTMENTS,
} from "./types";

// Action to get the department settings
export const getSettings = (department) => async (dispatch) => {
  try {
    // Send the reques to get settings
    const res = await defaultAxios.get(`/departments/${department}`);

    // Dispatch the result
    dispatch({
      type: GET_SETTINGS,
      payload: res.data,
    });
  } catch (error) {
    // Dispatch alert
    // const errors = error.response.data.errors;
    // if (errors)
    //     errors.forEach(error => dispatch(setAlerts(error.msg, "danger")));
  }
};

export const getIntakes = () => async (dispatch) => {
  try {
    const intakes = await defaultAxios.get("/intakes");

    dispatch({ type: SET_INTAKES, data: { intakes: intakes.data.intakes } });
  } catch (err) {
    alert("Error Occured while fetching the intakes");
  }
};

export const getPrograms = () => async (dispatch) => {
  try {
    const programs = await defaultAxios.get("/programs");

    dispatch({
      type: SET_PROGRAMS,
      data: { programs: programs.data.programs },
    });
  } catch (err) {
    alert("Error Occured while fetching the programs.");
  }
};

export const getDepartments = () => async (dispatch) => {
  try {
    const departments = await defaultAxios.get("/departments");

    dispatch({
      type: SET_DEPARTMENTS,
      data: { departments: departments.data.departments },
    });
  } catch (err) {
    alert("Error Occured while fetching the departments !");
  }
};
