import React from "react";
import { connect } from "react-redux";
import { isRoutePermitted } from "../../../utils/tools";

class PrivateView extends React.PureComponent {
  render() {
    const accessService = {
      ...this.props.accessServices[this.props.accessService],
    };

    return (
      isRoutePermitted(
        this.props.user,
        accessService.accessLevel,
        accessService.departments,
      ) && this.props.children
    );
  }
}

const mapPropsToState = (store) => {
  return {
    user: store.auth.user,
    accessServices: store.auth.accessServices,
  };
};

export default connect(mapPropsToState, null)(PrivateView);
