import React from "react";
import { useHasPermission } from "../hooks/useHasPermission";
import { Route, Redirect } from "react-router-dom";
import PageNotFound from "../components/pages/pageNotFound/PageNotFound";
import { useUser } from "../api/user";
import Loading from "..//components/layout/Loading/Loading";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const ProtectedRoute = ({
  component: Component,
  claimWithPermission = [],
  claimWithDepartment,
  claimWithRole,
  auth: { isAuthenticated },
  ...rest
}) => {
  // check is isAuthenticated, should we check that
  // get the current user with all their permission in the database
  const { isAllowed, isLoading } = useHasPermission(
    claimWithPermission,
    claimWithDepartment,
    claimWithRole,
  );

  if (isAuthenticated === null || isLoading) {
    return <Route {...rest} render={(props) => <Loading {...props} />} />;
  } else if (isAuthenticated) {
    if (isAllowed) {
      return <Route {...rest} render={(props) => <Component {...props} />} />;
    } else {
      return (
        <Route {...rest} render={(props) => <PageNotFound {...props} />} />
      );
    }
  } else {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )}
      />
    );
  }
};

ProtectedRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(ProtectedRoute);
