const config = {
    name: "Loyalist College in Toronto",
    server: "https://services.tbcollege.com/apiapi",
    url: "http://services.tbcollege.com",
    moodle: "https://moodle.tbcollege.com",
    chatToken: "",
    forgetPassword: "https://moodle.tbcollege.com/moodle/login/forgot_password.php",
    freshChat: "23522616-5770-41fc-aad1-6ca1912dfc3d",
    theme: {
        primary: "danger",
    },
    stripePk: "pk_live_51Jr0fcFSguzVZNNW2hjYBbCvzEahAIxmIQxQwTPdxQGxCSQGOHwrjIwb8Y3AcFDwBXIxb8sb7cmez1xYAxv5KhjK00kCMNCvuG",
    stripeReturnUrl: "https://services.tbcollege.com/shuttlepass",
    buspassId: "price_1OU7dlFSguzVZNNWf0W8U9gz",
    wonderland_id: "price_1LmkCPFSguzVZNNWAKFtweQD",
    REACT_APP_RQ_ENV: "production"
}
export default config;
