import { SET_INTAKES, SET_PROGRAMS, SET_DEPARTMENTS } from "../actions/types";

const initialState = {
  loading: true,
  intakes: null,
  programs: null,
  departments: null,
  documentsData: [
    {
      department: "Orientation",
      documents: [
        "Immigration Document",
        "passport",
        "feeReceipt",
        "LOA",
        "preArrival",
        "AIP",
        "Study Permit",
        "Study Permit Approval letter",
        "Study Permit Application Screenshot",
        "TRV Stamp or Sticker",
      ],
    },
    { department: "Retakes", documents: ["fee Reciept"] },
    {
      department: "Quarantine",
      documents: [
        "Quarantine Agreement",
        "TRV Stamp or Sticker",
        "Fee Receipt",
        "POC",
        "Hotel Reservation Form",
        "OQCD",
        "Flight Ticket",
      ],
    },
    { department: "Schedules", documents: [] },
  ],
  // naviation data is not being used in nav bar and dashboard
  // check useNavigationData under hook
  navigationData: [
    {
      key: "TermPass",
      label: "Shuttle Pass",
      path: "/shuttlepass",
      permission: "approvedStudents",
    },
    {
      key: "events",
      label: "Events",
      path: "/events",
      permission: "eventsNdStudent",
    },
    {
      key: "services",
      label: "Services",
      path: "/",
      permission: "approvedStudents",
      dropdown: [
        {
          key: "food-basket",
          label: "Food Basket",
          path: "/food-basket",
          permission: "approvedStudents",
        },
      ],
    },
    {
      key: "food-basket",
      label: "Food Basket",
      path: "/food-basket",
      permission: "superOnly",
    },
    // {
    //   key: "idCard",
    //   label: "ID Card",
    //   path: "/idcard",
    //   permission: "approvedStudents",
    // },
    {
      key: "idCards",
      label: "ID Cards",
      path: "/idcard",
      permission: "orientation", // TODO: Change this to a new permission when feature finished
    },
    {
      key: "Withdrawal Application",
      label: "Withdrawal",
      path: "/withdrawal",
      permission: "approvedStudents",
    },
    {
      key: "Completion Letter Application",
      label: "Completion",
      path: "/completion",
      permission: "approvedStudents",
    },
    {
      key: "Immigration Documents",
      label: "Immigration Documents",
      path: "/immigration",
      permission: "approvedStudents",
    },
    // {
    //   key: "academics",
    //   label: "Academics",
    //   permission: "superOnly",
    //   path: "/courses",
    //   dropdown: [
    //     {
    //       key: "academicsCourses",
    //       label: "Courses",
    //       path: "/courses",
    //       permission: "superOnly",
    //     },
    //     {
    //       key: "academicsPrograms",
    //       label: "Programs",
    //       path: "/programs",
    //       permission: "superOnly",
    //     },
    //     {
    //       key: "academicsClasses",
    //       label: "Classes",
    //       path: "/classes",
    //       permission: "superOnly",
    //     },
    //   ],
    // },
    {
      key: "view profiles",
      label: "Student Profiles",
      path: "/profile/",
      permission: "profile",
      dropdown: [
        {
          key: "view profiles",
          label: "Student Profiles",
          path: "/profile/",
          permission: "profile",
        },
        {
          key: "withdrawal",
          label: "Withdrawal",
          path: "/withdrawal",
          permission: "profile",
        },
        {
          key: "completion",
          label: "Completion",
          path: "/completion",
          permission: "profile",
        },
      ],
    },
    {
      key: "asasa",
      label: "Registration",
      permission: "orientationNdRegistrationStd",
      path: "/orientation",
      dropdown: [
        {
          key: "asasdsa",
          label: "Registrations",
          path: "/orientation",
          permission: "orientationNdRegistrationStd",
        },
        {
          key: "avsrfratresa",
          label: "Add Students",
          path: "/createStudents",
          permission: "orientationAdmins",
        },
        {
          key: "avsrfrttt",
          label: "All Students",
          path: "/students/list",
          permission: "orientation",
        },
      ],
    },
    // {
    //   key: "retakes",
    //   label: "Retakes",
    //   path: "/retake/",
    //   permission: "retakesNdApprovedStd",
    // },
    {
      key: "schedules",
      label: "Schedules",
      path: "/groups/",
      permission: "GROUP_ADMIN",
      dropdown: [
        {
          key: "avsrfratresa",
          label: " View Groups ",
          path: "/groups/",
          permission: "GROUP_ADMIN",
        },
        {
          key: "viewgroups",
          label: " Student list ",
          path: "/selected/groups",
          permission: "GROUP_ADMIN",
        },
        {
          key: "viewGroupEligibilityList",
          label: " Group Eligibility ",
          path: "/students/groupEligibilityList",
          permission: "GROUP_ADMIN",
        },
      ],
    },
    {
      key: "aferfsa",
      label: "View Schedule",
      path: "/schedules/",
      permission: "approvedStudents",
    },
    {
      key: "Coop",
      label: "Co-op",
      path: "/coop",
      permission: "careerNdStudent",
    },
    // ⛔️ Removing documents feature until needed
    {
      key: "Documents",
      label: "Documents",
      permission: "allAdminsNdSuper",
      path: "/documents/immigrationlist",
      dropdown: [
        {
          key: "docs",
          label: "Documents",
          path: "/documents/studentlist",
          permission: "allAdminsNdSuper",
        },
        {
          key: "immigration",
          label: "Immigration ",
          path: "/documents/immigrationlist",
          permission: "allAdminsNdSuper",
        },
      ],
    },
    {
      key: "DocumentsList",
      label: "Documents",
      path: "/documents/dashboard",
      permission: "approvedStudents",
    },
    {
      key: "Reports",
      label: "Reports",
      permission: "career",
      path: "/reports",
    },
    {
      key: "settings",
      label: "Settings",
      path: "/settings/users",
      permission: "superOnly",
      dropdown: [
        {
          key: "staff_settings",
          label: " Staff Settings ",
          path: "/settings/users",
          permission: "superOnly",
        },
        {
          key: "intake_settings",
          label: " Intake Settings ",
          path: "/settings/intakes",
          permission: "superOnly",
        },
        {
          key: "pre_schedules_settings",
          label: "Pre Schedules",
          path: "/settings/preSchedules",
          permission: "superOnly",
        },
        {
          key: "post_schedules_settings",
          label: "Post Schedules",
          path: "/settings/postSchedules",
          permission: "superOnly",
        },
      ],
    },
  ],
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "GET_SETTINGS": {
      return {
        ...state,
        loading: false,
        [payload.name]: payload,
      };
    }

    case SET_PROGRAMS: {
      return {
        ...state,
        programs: action.data.programs,
      };
    }

    case SET_INTAKES: {
      return {
        ...state,
        intakes: action.data.intakes,
      };
    }

    case SET_DEPARTMENTS: {
      return {
        ...state,
        departments: action.data.departments,
      };
    }

    default:
      return state;
  }
};
