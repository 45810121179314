const Service = "Quarantine";

const Permissions = {
  QUARANTINE_ADMIN: {
    accessLevel: [2, 0],
    departments: { [Service]: new Set(["admin"]) },
  },
  QUARANTINE_ALL: {
    accessLevel: [2, 0],
    departments: { [Service]: new Set(["$all"]) },
  },
  QUARANTINE_ALL_PROFILE: {
    accessLevel: [2, 1, 0],
    departments: { [Service]: new Set(["$all"]) },
  },
  QUARANTINE_ADMIN_PROFILE: {
    accessLevel: [2, 1, 0],
    departments: { [Service]: new Set(["admin"]) },
  },

  // Only refers to excluding the super admin
  PROFILES_ONLY: { accessLevel: [1], departments: {} },
};

export default Permissions;
