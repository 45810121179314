import axios from "axios";
import { createStore } from "redux";

import store from "../store";
import { logout } from "../actions/auth";
import jwtDecode from "jwt-decode";
import { toaster } from "evergreen-ui";
import config from "../config";

const defaultAxios = axios.create({
  baseURL: config.server,
  timeout: 30000,
});

defaultAxios.interceptors.request.use(
  async (config) => {
    // const token = localStorage.getItem('token');
    config.headers["x-auth-token"] = store.getState().auth.token;
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

defaultAxios.interceptors.response.use(
  (response) => {
    let token = localStorage.getItem("token");
    // console.log(token)
    // if(token && token.length > 0){
    // let decodedToken = jwtDecode(response.config.headers['x-auth-token'])
    // let currentTime = (new Date().getTime() + 1) / 1000;
    // let timeLeft = decodedToken.exp - currentTime;
    // setTimeout(function(){
    // // 1 : call the action that sends request to a new route to regenerate token ..
    // // 2 : Dispatch the login success action in the action too.
    // // store.dispatch(regenerateToken(decodedToken.user.id, decodedToken.user.stafflogin));
    // store.dispatch(logout()) // }, (Math.round(timeLeft) - 2) * 1000); // }
    return response;
  },
  async (error) => {
    if (!error.response) {
      toaster.danger("Internal server error");
    } else {
      // if it is an error blob object, will just return the blob
      // ref: https://github.com/axios/axios/issues/3779
      if (error.response.config.responseType === "blob") {
        const responseData = await error.response?.data?.text();
        return Promise.reject(JSON.parse(responseData));
      }
      switch (error.response.status || 500) {
        case 401:
          // console.log(error.response)
          if (
            error.response.data &&
            error.response.data.errors[0].type === "token"
          ) {
            console.log("valid");
          }
          // if(error.response.data && error.response.data.errors[0].type === "token"){
          // toaster.danger(error.response.data.errors[0].msg , {
          // duration: 2
          // }); // store.dispatch(logout())
          // }else{
          // toaster.danger( 'Permission Denied')
          error.response.data &&
            error.response.data.errors.map((item) => {
              toaster.danger(item.msg, { duration: 2 });
            });
        // }
        case 403:
          // toaster.danger( 'Permission Denied')
          error.response.data &&
            error.response.data.errors.map((item) => {
              toaster.danger(item.msg, { duration: 2 });
            });
          break;
        case 422:
          // toaster.danger('Validation Error');
          console.log(error.response.data);
          error.response.data &&
            error.response.data.errors.map((item) => {
              toaster.danger(item.msg, { duration: 4 });
            });
          break;
        case 400:
          // toaster.danger('Something Wrong');
          if (error.response.data) {
            error.response.data &&
              error.response.data.errors.map((item) => {
                toaster.danger(item.msg, { duration: 5 });
              });
          } else {
            toaster.danger("Something Wrong");
          }
          break;
        case 404:
          if (error.response) {
            error.response.data &&
              error.response.data.errors.map((item) => {
                toaster.danger(item.msg);
              });
            console.log(error.response);
          } else {
            toaster.danger("Something Wrong");
          }
          break;
        case 500:
          if (error.response) {
            error.response &&
              error.response.data.errors.map((item) => {
                toaster.danger(item.msg);
              });
          } else {
            toaster.danger(
              "Internal Server Error, Please contact support in case of inconvenience",
            );
          }
          break;
        default:
          break;
      }
    }
    console.log("#AXIOS", error);
    // }
    return Promise.reject(error.response);
  },
);

export default defaultAxios;
