import {
  GET_GROUPS,
  GET_ACADEMIC_PROFILE,
  SET_GROUP,
  SET_SCHEDULE_LOADING,
} from "../actions/types.js";

const initialState = {
  groups: [],
  schedules: {},
  loading: true,
  academic: {},
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_GROUPS:
      return {
        ...state,
        groups: payload,
        loading: false,
      };
    case GET_ACADEMIC_PROFILE:
      return {
        ...state,
        loading: false,
        academic: payload,
      };
    case SET_GROUP:
      return {
        ...state,
        groups: payload,
        loading: false,
      };

    case SET_SCHEDULE_LOADING:
      return {
        ...state,
        loading: payload,
      };
    default:
      return {
        ...state,
        loading: false,
      };
  }
};
