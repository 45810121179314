import { useQuery } from "react-query";
import defaultAxios from "../axios/Axios";

export type TDepartment = {
  _id: string;
  name: string;
  role: "admin" | "Member";
};

export type TUser = {
  _id?: string[];
  studentId?: [];
  academics?: any;
  class: string[];
  firstName?: string[];
  intake?: string[];
  lastName?: string[];
  middleName?: string[];
  program?: string[];
  firstSelectionEligibility?: boolean;
  orientationAlertSeen?: boolean;
  permission: number;
  status?: string;
  departments?: TDepartment[];
};

export const useUser = () =>
  useQuery("user", async (): Promise<TUser> => {
    const response = await defaultAxios.get("/auth");
    return response.data;
  });
