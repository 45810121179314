import { toaster } from "evergreen-ui";
import mainAxios from "../axios/Axios";
// function to view a file
/**
 *
 * @param {object} fileInfo
 * @param {string} fileInfo.name (optional?)
 * @param {string} fileInfo.code
 * @param {string} fileInfo.ext
 */
async function viewFile(
  fileInfo,
  apiEndPoint = "/documents/download",
  fileDepartment = "",
) {
  await mainAxios
    .post(apiEndPoint, { fileInfo, fileDepartment }, { responseType: "blob" })
    .then((res) => {
      const blob = new Blob([res.data], { type: res.data.type });

      const file = new File([blob], fileInfo.name, {
        type: res.data.type,
      });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);

      //Open the URL on new Window - urls just attached to the document
      window.open(fileURL);

      // https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL#memory_management
      URL.revokeObjectURL(file);
    })
    .catch((err) => {
      toaster.danger(err.errors[0].msg);
      return err;
    });
}

export default viewFile;
