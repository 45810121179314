const isRoutePermitted = (user, accessLevel = [], departmentsAccess = {}) => {
  try {
    let isPermitted = false;

    if (accessLevel.includes(user.permission)) {
      if (user.permission === 2) {
        const { departments } = user;

        for (let department of departments) {
          const currentDep =
            departmentsAccess["$all"] || departmentsAccess[department.name];

          if (!!currentDep) {
            isPermitted =
              currentDep.has("$all") || currentDep.has(department.role);
            if (isPermitted) break;
          }
        }
      } else {
        isPermitted = true;
      }
    }

    return isPermitted;
  } catch (err) {
    return false;
  }
};

export { isRoutePermitted };
