import defaultAxios from "../axios/Axios";
import { GET_CLASSES, GET_CLASSES_FILTERED_LIST } from "./types";

import { toaster } from "evergreen-ui";

const getClasses = () => async (dispatch) => {
  try {
    const classes = await defaultAxios.get("/classes");

    return dispatch({
      type: GET_CLASSES,
      payload: { classes: classes.data },
    });
  } catch (error) {}
};

export const getClassesFilteredList = (filter) => async (dispatch) => {
  try {
    //  setCourseLoading(true)

    let query = "";
    const staticFields = new Set(["page", "name", "intake"]);

    Object.keys(filter).forEach((key) => {
      if (staticFields.has(key) && !!filter[key]) {
        query = query + `${key}=${filter[key]}&`;
      }
    });

    const classes = await defaultAxios.get(`/classes/filter/list?${query}`);

    return dispatch({
      type: GET_CLASSES_FILTERED_LIST,
      payload: classes.data,
    });
  } catch (error) {
    throw error;
  }
};

export const deleteClass = (id) => async (dispatch) => {
  try {
    const res = await defaultAxios.delete(`/classes?id=${id}`);
    if (res.status === 200) {
      toaster.success("Class Deleted", { duration: 1 });
    }
  } catch (err) {
    throw err;
  }
};

export const editClass = (clas) => async (dispatch) => {
  try {
    const res = await defaultAxios.put(`/classes`, clas);
    if (res.status === 200) {
      toaster.success("Class Updated", { duration: 1 });
    }
  } catch (err) {
    throw err;
  }
};

export const addClass = (clas) => async (dispatch) => {
  try {
    const res = await defaultAxios.post(`/classes`, clas);
    if (res.status === 200) {
      toaster.success("New class added !", { duration: 1 });
    }
  } catch (err) {
    throw err;
  }
};

export { getClasses };
