import {
  GET_ORIENTATION_LIST,
  ORIENTATION_LOADING,
  SET_ORIENTATION,
  ORIENTATION_COUNT,
  ORIENTATION_REQUEST_LOADING,
} from "../actions/types.js";

const initialState = {
  list: [{ totalData: [] }],
  application: false,
  count: 0,
  loading: false,
  request: true,
  error: false,
};

export default (state = initialState, action) => {
  // Get the type and payload
  const { type, payload } = action;

  switch (type) {
    case GET_ORIENTATION_LIST:
      return { ...state, list: payload, loading: false };
    case SET_ORIENTATION:
      return { ...state, application: payload, loading: false };
    case ORIENTATION_LOADING:
      return { ...state, loading: payload };
    case ORIENTATION_REQUEST_LOADING:
      return { ...state, request: payload };
    case ORIENTATION_COUNT:
      return { ...state, count: payload };
    default:
      return state;
  }
};
