import { combineReducers } from "redux";
import alerts from "./alerts";
import auth from "./auth";
import retakes from "./retakes";
import orientation from "./orientation";
import countries from "./countries";
import schedules from "./schedules";
import settings from "./settings";
import student from "./student";
import quarantine from "./quarantine";
import documents from "./documents";
import classes from "./classes";
import courses from "./courses";
import programs from "./programs";
import shuttle from "./shuttle";

const allReducers = combineReducers({
  alerts,
  auth,
  retakes,
  orientation,
  countries,
  schedules,
  settings,
  quarantine,
  documents,
  classes,
  courses,
  student,
  programs,
  shuttle,
});

export const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }

  return allReducers(state, action);
};
