import { GET_QUARANTINE_LIST, QUARANTINE_LOADING } from "../actions/types.js";

const initialState = {
  list: [{ totalData: [] }],
  application: false,
  count: 0,
  loading: false,
  request: true,
  error: false,
};

export default (state = initialState, action) => {
  // Get the type and payload
  const { type, payload } = action;

  switch (type) {
    default:
      return state;
  }
};
