import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import downloadFile from "../../../utils/downloadFile";

// for downloading images
const PhotoDownload = () => {
  // name = student ID
  // code = file uuid
  // ext = file extension
  const params = useParams();

  useEffect(() => {
    params && downloadFile(params);
    setTimeout(() => {
      window.close();
    }, 5000);
  }, [params]);

  return <div>This page will close after the download is complete.</div>;
};

export default PhotoDownload;
